<template>
  <section class="regions">
    <div class="w-100 mt-3">
      <custom-filter
        :create-button="false"
        @handleSearch="handleSearch"
        @handleAdd="handleAdd"
      >
        <template slot="customFilters">
<!--          <b-col cols="2">-->
<!--            <b-form-group>-->
<!--              <b-form-select-->
<!--                v-model="filters.status_id"-->
<!--                :options="statuses"-->
<!--                text-field="name"-->
<!--                value-field="id"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
          <b-col cols="2">
            <b-form-group>
              <b-form-select
                v-model="filters.nomination_id"
                :options="nominations"
                text-field="title"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                v-model="filters.region"
                :options="allRegions"
                text-field="nameuz"
                value-field="id"
                @input="fetchRayons"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                v-model="filters.rayon"
                :options="rayons"
                text-field="region"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                v-model="filters.jury"
                :options="juries"
                text-field="full_name"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="warning"
              @click="getClear"
            >
              <b-icon icon="arrow-repeat" />
            </b-button>
            <b-button
              variant="info"
              class="ml-1"
              @click="getSearch"
            >
              <b-icon icon="search" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="success"
              size="sm"
              @click="fireAllStautses"
            >
              {{ $t('message.ShowStatusAll') }}
            </b-button>
          </b-col>
        </template>
      </custom-filter>
      <div class="w-100 mb-1">
        <h6>{{$t('message.TotalCount')}} : {{total}}</h6>
      </div>
      <b-card>
        <b-table
          :items="datas"
          :fields="fields"
        >
          <template #cell(user)="{item}">
            <p>
              {{ item.user.first_name }} {{ item.user.last_name }}
            </p>
          </template>
          <template #cell(created_at)="{item}">
            {{ item.created_at | dateBueutify }}
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button
                variant="primary"
                size="sm"
                class="ml-1"
                @click="handleEdit(data.item.id)"
              >
                <b-icon icon="eye" />
              </b-button>
              <b-button
                class="ml-1"
                variant="danger"
                size="sm"
                @click="handleDelete(data.item.id)"
              >
                <b-icon icon="trash" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
            v-model="pagination.page"
            hide-goto-end-buttons
            :total-rows="total"
            :per-page="pagination.pageSize"
            @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal
      id="modal-lg"
      v-model="modalVisible"
      size="xl"
      scrollable
      hide-footer
      :title="modalTitle"
    >
      <Form
        :id="id"
        :active-juries="datas.jury"
        @saved="fetchList"
      />
    </b-modal>
    <b-modal
      v-model="RejectModal"
      size="lg"
      hide-footer
      :title="$t('message.CauseOfReject')"
    >
      <div class="w-100">
        <b-form-group>
          <b-form-textarea
            id="textarea"
            v-model="reject.cause"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-end">
        <b-button
          variant="danger"
          class="mr-1"
          @click="getRejectApplication"
        >
          {{ $t('message.Save') }}
        </b-button>
        <b-button
          variant="dark"
          @click="RejectModal=false"
        >
          {{ $t('message.Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  getApplications,
  deleteApplication,
  getStatuses,
  rejectApplication,
  displayStatusesAll,
} from '@/api/application'
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import moment from 'moment'
import { getNominations } from '@/api/nomination'
import { getAllRegions, getRegionRayons } from '@/api/region'
import { getUsers } from '@/api/users'
import Form from './Form'

const actions = {
  get: getApplications,
  delete: deleteApplication,
}

export default {
  name: 'NewApplications',
  components: {
    CustomFilter,
    Form,
    moment,
  },
  filters: {
    dateBueutify(val) {
      return moment(val).format('DD.MM.YYYY')
    },
  },
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      actions,
      fields: [
        { key: 'id', label: this.$t('message.ID') },
        { key: 'nomination.title', label: this.$t('message.Nomination') },
        { key: 'user', label: this.$t('message.Nominant') },
        { key: 'status.name', label: this.$t('message.Status') },
        { key: 'user_marks', label: this.$t('message.Mark') },
        { key: 'created_at', label: this.$t('message.Date') },
        { key: 'action', label: this.$t('message.Action') },
      ],
      juries: [],
      statuses: [],
      questions: [],
      filters: {
        status_id: 1,
        search: null,
        statusIds: [1],
        nomination_id: null,
        region: null,
        rayon: null,
        jury: null,
      },
      RejectModal: false,
      reject: {
        cause: null,
        application_id: null,
      },
      nominations: [],
      rayons: [],
      allRegions: [],
    }
  },
  created() {
    getStatuses().then(res => {
      this.statuses = res.data.data
      this.statuses.unshift({ name: this.$t('message.Status'), id: null })
    })
    this.fetNominations()
    this.fetchRegions()
    this.fetchJuries()
  },
  methods: {
    getSearch() {
      this.pagination.page = 1
      this.fetchList()
    },
    handleSearch(search) {
      this.filters.search = search;
    },
    getClear() {
      this.pagination.page = 1
      this.filters.search = null
      this.filters.rayon = null
      this.filters.region = null
      this.filters.status_id = null
      this.filters.nomination_id = null
      this.filters.jury = null
      this.fetchList()
    },
    handleReject(id) {
      this.reject.application_id = id
      this.RejectModal = true
    },
    getRejectApplication() {
      rejectApplication({ ...this.reject, status: 'rejected' }).then(() => {
        this.RejectModal = false
        this.fetchList()
      })
    },
    fetNominations() {
      getNominations().then(res => {
        this.nominations = res.data.data.data
        this.nominations.unshift({ title: this.$t('message.Nomination'), id: null })
      })
    },
    fetchRegions() {
      getAllRegions().then(res => {
        this.allRegions = res.data.data.result
        this.allRegions.unshift({ nameuz: this.$t('message.Region'), id: null })
      })
    },
    fetchRayons() {
      getRegionRayons({ region_id: this.filters.region }).then(res => {
        this.rayons = res.data.data
        this.rayons.unshift({ region: this.$t('message.Rayon'), id: null })
      })
    },
    fetchJuries() {
      getUsers({ role_id: 3, pageSize: 30, page: 1 }).then(res => {
        this.juries = res.data.data.data.map(item => ({ id: item.id, full_name: (`${item.last_name} ${item.first_name}`) }))
      })
    },
    fireAllStautses() {
      this.$swal({
        title: this.$t('message.areYouSure'),
        text: this.$t('message.wontReturn'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('message.Edit'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          displayStatusesAll()
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: this.$t('message.Cancelled'),
            text: this.$t('message.yrDataSafe'),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

}
</script>

<style scoped>

</style>
